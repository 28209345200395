import "./process.css"
import 'react-phone-number-input/style.css'
import React,{useEffect, useState} from "react";
import { useParams,useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import p1 from '../../Components/asserts/p3.webp'
import p3 from '../../Components/asserts/ft1.jpeg'
import p6 from '../../Components/asserts/p6.webp'
import p7 from '../../Components/asserts/ft2.jpeg'
import p8 from '../../Components/asserts/p3.webp'

import p10 from '../../Components/asserts/img3.webp'
import p11 from '../../Components/asserts/img5.jpeg'
import p12 from '../../Components/asserts/img2.webp'
import p13 from '../../Components/asserts/img1.webp'
import p14 from '../../Components/asserts/img4.jpeg'

import vd from '../../Components/asserts/cvd.mp4'
import Footer from '../../Components/Forteer'
import Logo from '../../Components/asserts/logo.png';
import hacker from '../../Components/asserts/hakcer.gif';

import {i18n} from '../../Translate/i18n';
import axios from "axios";

 function Processando() {
  const {telefone}= useParams();
 

  const [status,setStatus] = useState('')
  const navigate = useNavigate()
  const [foto,setFoto] = useState("")
  const [loading,setLoading] = useState(false)

  const phone = telefone.replace(/[,!+]/g, "")
  console.log(phone)

  const posts = [
    {
      id:"1",
      nome:"Juana008",
      perfil:p1,
      coments:"Aplicativo funciona mesmo"
    },
    {
      id:"2",
      nome:"Mariasilva675",
      perfil:p3,
      coments:"Aplicativo muito bom"
    },
   
    {
      id:"3",
      nome:"Michelle28747",
      perfil:p6,
      coments:"vi todas as conversas dele"
    },
    {
      id:"4",
      nome:"Fabiana747",
      perfil:p7,
      coments:"App incrivél"
    },
    {
      id:"5",
      nome:"Riquele675",
      perfil:p8,
      coments:"Descubrir tudo dele"
    },
    {
      id:"7",
      nome:"jade6832",
      perfil:p10,
      coments:"Vi todas as fotos"
    },
    {
      id:"9",
      nome:"manuelle374",
      perfil:p13,
      coments:"Obrigado viewerspy"
    },
    {
      id:"10",
      nome:"Cintia235",
      perfil:p12,
      coments:"Simplesmente fantastico"
    },
    {
      id:"11",
      nome:"Alexa2454",
      perfil:p13,
      coments:"incrivél"
    },
    {
      id:"12",
      nome:"Selma6384",
      perfil:p14,
      coments:"Muito bom"
    },
    {
      id:"13",
      nome:"Katia245",
      perfil:p11,
      coments:"Funcionou"
    },
    {
      id:"14",
      nome:"Francisca637",
      perfil:p11,
      coments:"Esse é top"
    }
    
  ]


 
  useEffect(()=>{
    async function foto(){
      try {

        const response = await axios.get(`https://whatsapp-data1.p.rapidapi.com/number/${phone}`,{
          headers: {
             'X-Rapidapi-Key' : '3f9cbd0771msh47895941b9b358dp1a4977jsn0df0ed49bbc8'
        }})

        setStatus(response.data.about)
        
      console.log(response.data)
      setFoto(response.data.profilePic)

      setLoading(true)

        
      } catch (error) {
        console.error(error);
        
      }
    }

    
    foto()

    var i = 0;
  function move() {
    if (i == 0) {
      i = 1;
      var elem = document.getElementById("myBar");
      var progress = document.getElementById("progress")
      var width = 1;
      var id = setInterval(frame, 100);
      function frame() {
        if (width >= 100) {
          clearInterval(id);
          i = 0;
        } else {
          width++;
          elem.style.width = width + "%";
          progress.innerHTML = width + "%";

          
           if(elem.style.width =="100%"){
            Swal.fire({
              title: `${i18n.t('titles.seq3')}`,
              icon: "success",
              confirmButtonText: `${i18n.t('buttons.btn')}`,
              text: `${i18n.t('titles.seq4')}`,
             
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                elem.style.visibility = "hidden";
             navigate(`/provas/${phone}`)
              } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
              }
            });
          }
        }
      }
    }
  }
  move()
  
  },[])


  
 return (
  <div className="cont">
    <Header/>
   <div className="boxLoading">
  
  <div className="pt">
  <div className="blss"></div>
  <div><span>{i18n.t('titles.titu')}</span></div>
  </div>
    <img src={hacker} width={300} height={200} />

    <div className="bxmages">
      <div>{loading ? <img src={foto} width={63}/> : false} </div>
      <div><span>{telefone}</span></div>
      </div>

      <h3>"{status}"</h3>
      
     

    <div className="boxlocalisando">
     <div className="boxprogresso">
     <div id="progress"></div>
     <div ><span>Localizando...</span></div> 
   
     </div>
      <div id="myProgress">
  <div id="myBar"></div>
</div>
    </div>

    <div className="separador"></div>
    <div id="acessando" className="boxList">
      <span> <ClipLoader
      color="green"
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      />Acessando dispositivo...</span>
      <span> <ClipLoader
      color="green"
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      />{i18n.t('boxpainel.acesso')}</span>
      <span><ClipLoader
      color="green"
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> {i18n.t('boxpainel.info')}</span>
      <span><ClipLoader
      color="green"
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> {i18n.t('boxpainel.loc')}</span>
    </div>
    
    <div className="separador"></div>

    <h2>{i18n.t('titles.tituloseq')}</h2>
    <div className="scrool">
    <div className='mages'>
 
      {
        posts.map((item=>{
          return(
            
              <div className='bx1' key={item.id}>

<div className='boxcoments'>
<img src={item.perfil} /> 


</div>

<div className='itemsposts'>
<span>{item.nome}</span>
<p>{item.coments}</p>
</div>

<div className='blsss'></div>
</div>
      
          )
  
        }))
      }
      
      </div>
    </div>
  
   </div>
   
   
  </div>
  
  );
 }


export default Processando;