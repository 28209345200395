import './App.css';
import 'react-phone-number-input/style.css'
import React,{useState,useEffect} from 'react';
import Header from "../../Components/Header";
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import useGeoLocation from "react-ipgeolocation";
import {i18n} from '../../Translate/i18n';
import WhatsappIcon from '../../Components/asserts/Whatsapp-Icon.png'
import iphone from '../../Components/asserts/iphone.svg'
import macewindows from '../../Components/asserts/mac-or-windows.svg'
import windowsphone from '../../Components/asserts/windows-phone.svg'
import android from '../../Components/asserts/android.svg'
import p1 from '../../Components/asserts/p3.webp'
import p3 from '../../Components/asserts/ft1.jpeg'
import p6 from '../../Components/asserts/p6.webp'
import p7 from '../../Components/asserts/ft2.jpeg'
import p8 from '../../Components/asserts/p3.webp'

import p10 from '../../Components/asserts/img3.webp'
import p11 from '../../Components/asserts/img5.jpeg'
import p12 from '../../Components/asserts/img2.webp'
import p13 from '../../Components/asserts/img1.webp'
import p14 from '../../Components/asserts/img4.jpeg'

import Swal from "sweetalert2";
import axios from "axios";
import Modal from 'react-modal';
import LoadingBar from 'react-top-loading-bar'
import {FaCheckCircle } from "react-icons/fa";
import Footer from '../../Components/Forteer'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

function Home() {
    const [telefone, setTelefone] = useState("");
    const location = useGeoLocation();
    const I18N_STORAGE_KEY = 'i18nextLng'
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [progress, setProgress] = useState(0)
    const [ip, setIp] = useState(0)
    const [st, setst] = useState('bl')
    const [stm, setstm] = useState('blk')
    const [bv, setbv] = useState('bl')
    const [bm, setbm] = useState('bl')
    const [bz, setbz] = useState('bl')
    const [bd, setbd] = useState('bl')
    const [ba, setba] = useState('bl')
    const [bs, setbs] = useState('bl')
    const [abtn, setabtn] = useState('abtn')
    const [start, setStart] = useState(false)
    const data = new Date();
    const novadata = data.toLocaleDateString();
    const posts = [
      {
        id:"1",
        nome:"Juana008",
        perfil:p1,
        coments:"Aplicativo funciona mesmo"
      },
      {
        id:"2",
        nome:"Mariasilva675",
        perfil:p3,
        coments:"Aplicativo muito bom"
      },
     
      {
        id:"3",
        nome:"Michelle28747",
        perfil:p6,
        coments:"vi todas as conversas dele"
      },
      {
        id:"4",
        nome:"Fabiana747",
        perfil:p7,
        coments:"App incrivél"
      },
      {
        id:"5",
        nome:"Riquele675",
        perfil:p8,
        coments:"Descubrir tudo dele"
      },
      {
        id:"7",
        nome:"jade6832",
        perfil:p10,
        coments:"Vi todas as fotos"
      },
      {
        id:"9",
        nome:"manuelle374",
        perfil:p13,
        coments:"Obrigado viewerspy"
      },
      {
        id:"10",
        nome:"Cintia235",
        perfil:p12,
        coments:"Simplesmente fantastico"
      },
      {
        id:"11",
        nome:"Alexa2454",
        perfil:p13,
        coments:"incrivél"
      },
      {
        id:"12",
        nome:"Selma6384",
        perfil:p14,
        coments:"Muito bom"
      },
      {
        id:"13",
        nome:"Katia245",
        perfil:p11,
        coments:"Funcionou"
      },
      {
        id:"14",
        nome:"Francisca637",
        perfil:p11,
        coments:"Esse é top"
      }
      
    ]

    async function openModal() {
      setIsOpen(true);
      setStart(true)
   
      const response = await axios.get('https://ipapi.co/json/')

  setIp(response.data.ip)
    
    }
   
    function closeModal() {
      setIsOpen(false);
    }
   useEffect(()=>{






    function selectLanguagem(){
      localStorage.setItem(
        I18N_STORAGE_KEY,
        location.country
      )

     
     
    }

    selectLanguagem()


   
    
   },[])

   

   if(start == true){
    
    startbar()
    
  }else{
    
    clearTimeout(startbar)
   
  }

  function startbar(){
  
 setTimeout(()=>{
      
   
    setProgress(progress+1)
    
      
if(progress == 11){
  setst('bt')
  setstm('bt')

}else
if(progress == 21){
  setbv('blk')
 
}
else
if(progress == 31){

  setbm('bt')
  setbv('bc')
 
}
else
if(progress == 41){
  setbz('blk')
 
}
else
if(progress == 51){
  setbd('bt')
  setbz('bc')
 
}
else
if(progress == 61){
  setba('blk')

 
}
else
if(progress == 100){
  setbs('bt')
  setba('bc')
  setabtn('btna')
  setProgress(100)
  setStart(false)
 


 
}

 
 },1000)

  }
   

 
   

  return (
    <div className='containerBox'>
    
   

    <Header/>

      <div className='boxinfo'>
        
        <img src={WhatsappIcon} width={300} />
        <h1>{i18n.t('titles.app')} <br></br>
          <b> {i18n.t('titles.appi')}</b>
        </h1>
     
          <div className='separator'></div>
          <PhoneInput
              defaultCountry={location.country}
              value={telefone}
              initialValueFormat='international'
              onChange={setTelefone}
              placeholder={i18n.t('messages.inputtext')}
              error={telefone ? (isPossiblePhoneNumber(telefone) ? undefined : 'Invalid phone'):
            'Phone number requirer'

            }
          
             
          />
       
       
       
        <Link to={`processando/${telefone}`} >{i18n.t('buttons.save')}</Link>
        <br/>
        
        <div className='boxicones'>
          
        <div className='boxiconesgeral' >
          <img  src={android} />
          <div ><span>Android</span></div>
          </div>
        <div className='boxiconesgeral'><img  src={iphone} />
       <span>Iphone</span>
        </div>
        <div className='boxiconesgeral'><img  src={macewindows} />
        <span>Windows</span>
        </div>
        <div className='boxiconesgeral'><img src={windowsphone} />
        <span>Macbook</span>
        </div>

        </div>
        <br/>
        <h2>{i18n.t('titles.tituloseq')}</h2>
        <div className="scrool">
        
    <div className='mages'>
      
      {
        posts.map((item=>{
          return(
            
              <div className='bx1' key={item.id}>

<div className='boxcoments'>
<img src={item.perfil} /> 


</div>

<div className='itemsposts'>
<span>{item.nome}</span>
<p>{item.coments}</p>
</div>

<div className='blsss'></div>
</div>
      
          )
  
        }))
      }
      
      </div>
    </div>

          <div className='bxdate'><h4>{i18n.t('titles.menBox')}</h4>
          <span>+100K</span>
          </div>

          <div className='bxdate'><h4>{i18n.t('titles.titulo')}</h4>
          <span>{novadata}</span>
          </div>
         
          
     
      
         
      
      </div>
     <Footer/>
     </div>
     
  );
}

export default Home;
